<template>
    <div class="selectors">
        <multiselect
            v-if="!personHealthPlan"
            track-by="id"
            placeholder="Selecionar convênio..."
            v-model="personHealthPlan"
            :options="healthPlans"
            :showLabels="false"
            :internalSearch="false"
            :searchable="false"
            class="with-border"
            :disabled="!patientId"
            @select="onSelectPersonHealthPlan"
        >
            <template slot="caret">
                <div v-if="!loading" class="chevron">
                    <ChevronDown />
                </div>
                <div v-else class="loading-icon">
                    <b-spinner small type="grow" variant="primary"></b-spinner>
                </div>
            </template>
            <template slot="option" slot-scope="{ option }">
                {{ option.health_plan?.fantasy_name }}
            </template>
            <template slot="noOptions">
                Nenhuma opção
            </template>
            <template slot="noResult">
                Nenhum resultado
            </template>
            <template v-if="patientId" slot="afterList">
                <div class="new-health-plan" @click="openHealthPlansModal">Adicionar convênio</div>
            </template>
        </multiselect>

        <div v-else class="custom-input-prefix">
            <div
                class="profile-prefix"
                :style="disableHealthPlanSelection && { cursor: 'default' }"
                :aria-disabled="disableHealthPlanSelection"
                @click="!disableHealthPlanSelection && clearSelectors()"
            >
                <Ellipsis>{{ personHealthPlan?.health_plan?.fantasy_name }}</Ellipsis>
                <span v-if="selectedPlan">&nbsp;|&nbsp;</span>
                <Ellipsis v-if="selectedPlan">{{ selectedPlan.name }}</Ellipsis>
                <div v-if="!selectedPlan && plans.filter(p => p.health_plan_id === personHealthPlan.health_plan_id).length">
                    <PlanIcon 
                        v-if="!showSelectPlan"
                        class="plan-icon" 
                        :class="{ active: false }"
                        @click="showPlans"
                        v-b-tooltip.hover title="Adicionar um plano"
                    />
                    <CancelIcon 
                        v-else
                        class="plan-icon" 
                        @click="hidePlans"
                        v-b-tooltip.hover title="Cancelar seleção de plano"
                    />
                </div>
            </div>

            <multiselect
                v-if="!selectedPlan && showSelectPlan"
                track-by="id"
                label="name"
                placeholder="Selecionar plano..."
                v-model="selectedPlan"
                :options="plans.filter(p => p.health_plan_id === personHealthPlan.health_plan_id)"
                :showLabels="false"
                :internalSearch="true"
                :searchable="true"
                @select="onSelectPlan"
                :disabled="disableHealthPlanSelection"
            >
                <template slot="caret">
                    <div v-if="!loading" class="chevron">
                        <ChevronDown />
                    </div>
                    <div v-else class="loading-icon">
                        <b-spinner small type="grow" variant="primary"></b-spinner>
                    </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    <span class="item-label">{{ option.name }}</span>
                </template>
                <template slot="option" slot-scope="{ option }">
                    <span class="item-label">{{ option.name }}</span>
                </template>
                <template slot="noOptions">
                    Nenhuma opção
                </template>
                <template slot="noResult">
                    Nenhum resultado
                </template>
            </multiselect>
            
            <multiselect
                v-else
                track-by="id"
                label="name"
                placeholder="Selecionar item..."
                v-model="item"
                :options="items"
                :showLabels="false"
                :internalSearch="false"
                :searchable="true"
                @search-change="debounceSearch"
                @select="onSelectItem"
            >
                <template slot="caret">
                    <div v-if="!loading" class="chevron">
                        <ChevronDown />
                    </div>
                    <div v-else class="loading-icon">
                        <b-spinner small type="grow" variant="primary"></b-spinner>
                    </div>
                </template>
                <template slot="singleLabel" slot-scope="{ option }">
                    <span v-if="option?.health_plan_props?.alias_code || option?.code" class="code">{{ option?.health_plan_props?.alias_code ?? option?.code }} </span>
                    <span 
                        v-if="option?.health_plan_props?.alias_name?.length < 45 || option?.name?.length < 45"
                        class="item-label" 
                    >
                        {{ option?.health_plan_props?.alias_name ?? option?.name }}
                    </span>
                    <span 
                        v-else
                        class="item-label"
                        v-b-tooltip.hover :title="option?.health_plan_props?.alias_name ?? option?.name"
                    >
                        {{ option?.health_plan_props?.alias_name?.substring(0,45) ?? option?.name?.substring(0,45)}} ...
                    </span>
                </template>
                <template slot="option" slot-scope="{ option }">
                    <div>
                        <span v-if="option.code" class="code">Código: {{ option.code }} </span>
                        <span class="item-label">{{ option.name }}</span>
                    </div>
                    <ItemTagType :type="option.type" />
                    <p class="disabled-description" v-if="option.$isDisabled">
                        Este procedimento é realizado em outra sala, crie um novo agendamento para adicioná-lo.
                    </p>
                </template>
                <template slot="noOptions">
                    Nenhuma opção
                </template>
                <template slot="noResult">
                    Nenhum resultado
                </template>
            </multiselect>
        </div>

        <PatientHealthPlanModal 
            :editIndex="null"
            :addPersonHealthPlan="addPersonHealthPlan"
        />
    </div>
</template>

<script>
import debounce from 'lodash/debounce'
import api from '@items/api'
import { getCurrentClinic } from '@/utils/localStorageManager';

export default {
    name: 'ItemHealthPlanSelector',
    components: {
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        Ellipsis: () => import('@/components/General/Ellipsis'),
        ItemTagType: () => import('@items/components/ItemTagType'),
        PlanIcon: () => import('@items/assets/icons/plan.svg'),
        CancelIcon: () => import('@items/assets/icons/cancel.svg'),
        PatientHealthPlanModal: () => import('@/views/Patients/PatientHealthPlanModal'),
    },
    props: {
        value: {
            validator: v => true
        },
        patientId: {
            type: String
        },
        healthPlan: Object,
        plan: Object,
        allowedTypes: { 
            type: Array, 
            required: true 
        },
        availableProcedures: Array,
        disableHealthPlanSelection: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            clinic: getCurrentClinic(),
            loading: false,
            showSelectPlan: false,
            personHealthPlan: null,
            selectedPlan: null,
            item: null,
            healthPlans: [],
            plans: [],
            items: [],
            debounceSearch: Function
        }
    },
    created() {
        this.debounceSearch = debounce(this.searchItems, 300);
    },
    mounted() {
        this.getPatientHealthPlans()
        this.populateHealthPlan()
        this.populatePlan()
        this.populateValue()
    },
    methods: {
        clearSelectors() {
            this.items = []
            this.personHealthPlan = null
            this.selectedPlan = null
            this.item = null
        },
        onSelectPersonHealthPlan(personHealthPlan) {
            this.personHealthPlan = personHealthPlan
        },
        onSelectPlan() {
            this.showSelectPlan = false
            this.item = null;
        },
        onSelectItem(item) {
            this.$emit('select', { healthPlan: this.personHealthPlan.health_plan, plan: this.selectedPlan, item })
            this.$emit('input', item)
        },
        searchItems(query) {
            this.loading = true;
            api.searchItems(this.clinic.id, query, this.allowedTypes, this.personHealthPlan.clinic_health_plan_id || this.personHealthPlan?.health_plan?.clinic_health_plan[0]?.id, this.selectedPlan?.id)
                .then(({ data }) => {
                    this.items = Object.values(data).map(item => ({
                        ...item
                        // $isDisabled: !this.availableProcedures?.some(el => el.item_id === item.id)
                    }));
                })
                .catch(err => {
                    this.$toast.error(err.message)
                    console.error(err)
                })
                .finally(() => (this.loading = false));
        },
        getPatientHealthPlans() {
            if (!this.patientId) return;
            this.loading = true;
            this.api.getPatientHealthPlans(this.clinic.id, this.patientId, [])
                .then(res => {
                    this.healthPlans = res.data;
                    this.plans = res.data.filter(data => data.plan_id).map(data => data.plan);
                    if (this.healthPlans.length === 1) {
                        this.personHealthPlan = this.healthPlans[0];
                        if (this.plans.length === 1) {
                            this.selectedPlan = this.plans[0];
                        }
                    }
                })
                .catch(err => this.$toast.error(err.message))
                .finally(() => (this.loading = false));
        },
        showPlans($event) {
            $event.stopPropagation();
            if (this.plans.filter(p => p.health_plan_id === this.personHealthPlan.health_plan_id).length === 1) {
                this.selectedPlan = this.plans.find(p => p.health_plan_id === this.personHealthPlan.health_plan_id);
                return;
            }
            this.showSelectPlan = true;
        },
        hidePlans($event) {
            $event.stopPropagation();
            this.selectedPlan = null;
            this.showSelectPlan = false;
        },
        populateHealthPlan() {
            if (!this.healthPlan) return;
            !this.healthPlans.find(hp => hp.health_plan_id === this.healthPlan.id) &&
                this.healthPlans.push(this.healthPlan);
            this.personHealthPlan = { health_plan: this.healthPlan };
        },
        populatePlan() {
            if (!this.plan) return
            this.plans = [this.plan];
            this.selectedPlan = this.plan;   
        },
        populateValue() {
            if (!this.value) return
            this.items = [this.value]
            this.item = this.value
        },
        openHealthPlansModal() {
            this.$bvModal.show('new-medical-insurance-modal')
        },
        async addPersonHealthPlan(personHealthPlan) {
            this.clearSelectors()
            try {
                const payload = {
                    person_id: this.patientId,
                    health_plan_id: personHealthPlan.health_plan?.id,
                    plan_id: personHealthPlan.plan?.id,
                    registration: personHealthPlan.registration,
                    token: personHealthPlan.token,
                    valid_until: personHealthPlan.valid_until,
                    holder: personHealthPlan.holder,
                }
                await this.api.createHealthPersonPlan(payload)
                this.$bvModal.hide('new-medical-insurance-modal')
                this.getPatientHealthPlans()
            } catch (error) {
                this.$toast.error(error.message)
            }
        }
    },
    
    watch: {
        value() {
            this.populateValue()
        },
        patientId() {
            this.getPatientHealthPlans()
        },
        healthPlan() {
            this.populateHealthPlan()
        },
        plan() {
            this.populatePlan()
        },
    }
}
</script>

<style lang="scss" scoped>
.selectors {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.custom-input-prefix {
    width: 100%;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    height: 38px;
    border: 1px solid var(--neutral-300);
    padding: 15px 0 15px 16px;
    font-size: 14px;
    white-space: nowrap;
    margin-bottom: 8px;
    .profile-prefix {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 20%;
        font-weight: 400;
        color: var(--neutral-500);
        cursor: pointer;
    }
}
.loading-icon {
    display: flex;
    align-items: center;
    width: 24px;
    height:38px;
    padding: 0;
    right: 16px;
    position: absolute;
}
.item-label {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--dark-blue);
}
.code {
    font-size: 0.8rem;
    color: var(--blue-500);
}
.plan-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 2px;
    fill: var(--type-active);

    &.active {
        fill: var(--blue-500);
    }
}
.disabled-description {
  font-size: 12px;
  font-style: italic;
  color: var(--type-active);
}

.new-health-plan {
    padding: 0.75rem 1rem;
    background-color: white;
    margin: 4px 0 -10px 0;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.25);
    color: var(--blue-500);
    font-weight: 700;
    text-align: center;
  }
</style>